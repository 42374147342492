import React from 'react';

import { Main } from '@layout';
import { Seo, Divider } from '@common';
import { Hero, HighlightCards, NoDrama, FeatureHighlights } from '@home';

export default function IndexPage() {
  return (
      <div className="container">
        <h1>Site Coming Soon</h1>
        <p>
          This domain is registered and coming soon. Stay tuned for updates!
        </p>
        <div className="footer">
          &copy; 2024 panorama.im. All rights reserved.
        </div>
      </div>
  );
}


/* 
    
        <Seo description="Home page for a Gatsby Starter, featuring Emotion and Tailwind css" />
      <Hero />
      <Divider inTrigger={0} outTrigger="elOutY" />
      <HighlightCards />
      <Divider inTrigger="elInY" outTrigger="elOutY" />
      <NoDrama />
      <Divider inTrigger="elInY" outTrigger="elOutY" />
      <FeatureHighlights />
      </Main>
      */